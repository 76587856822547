import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"my-task-card cursor-move pb-0"},[_c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"task-btn",attrs:{"text":"","small":"","fab":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiMenu))])],1)]}}])},[_c(VList,{staticClass:"my-list"},[_c(VListItem,{staticClass:"pa-0"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.openAppealForm(_vm.task.id)}}},[_c(VIcon,{staticClass:"mr-3",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))]),_vm._v(" Tahrirlash ")],1)],1),_c(VListItem,{staticClass:"pa-0"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.removeCard('appeal', _vm.task.id)}}},[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"error","size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))]),_vm._v(" O'chirish ")],1)],1)],1)],1),_c('div',{staticClass:"my-task-content"},[_c('strong',[_vm._v(_vm._s(_vm.task.full_name))]),_c('span',[_vm._v(_vm._s(_vm.task.subject ? _vm.task.subject.name : null))]),_c('span',{staticClass:"my-task-phone"},[_vm._v(_vm._s(_vm.task.phone))]),_c('span',{staticClass:"my-task-date"},[_vm._v(_vm._s(_vm._f("date")(_vm.task.created_at)))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }