import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","width":"600px"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}],"click:outside":function($event){return _vm.close()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VForm,{ref:"form"},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-required no-text"},[_c('span',[_vm._v("*")])]),_c(VTextField,{attrs:{"label":"NOMI","dense":"","outlined":"","required":"","hide-details":"","rules":[_vm.required]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"gray","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Bekor qilish")]),_c(VBtn,{attrs:{"color":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v("Saqlash")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }